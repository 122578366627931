<script>
    import { modalStore } from '@skeletonlabs/skeleton';

    export let parent;
</script>

{#if $modalStore[0]}
    <div class='w-modal-slim card p-5 shadow-xl space-y-4 px-8 pt-8 pb-3 rounded-xl'>
        <h2 class='h2 text-3xl font-bold'>Thank You!</h2>

        <p>
            We're adding your building ASAP &mdash; most are
            approved within a few hours. You'll get an email
            once it's verified.
        </p>

        <section class='text-center'>
            <a href='/'
               on:click={() => modalStore.close()}
               class='btn rounded-xl text-primary-500 font-bold'>
                Back to Home >
            </a>
        </section>
    </div>
{/if}
