import { PUBLIC_ENV, PUBLIC_SUPABASE_KEY, PUBLIC_SUPABASE_URL } from '$env/static/public';
import { createSupabaseLoadClient } from '@supabase/auth-helpers-sveltekit';
import reviewProgressBar from '$lib/stores/reviewProgressBar.js';
import posthog from 'posthog-js';
import { browser } from '$app/environment';

const roleMap = {
    1: 'reviewer',
    2: 'admin',
    3: 'test_user',
    4: 'placeholder',
};

export const load = async ({ url, fetch, data, depends }) => {
    depends('supabase:auth');
    const { pathname } = url;

    if (browser) {
        posthog.init('phc_1xYEbUjElW1gpLlpINavBDlKYpgtbaXYbE1B7HCGWKf', {
            api_host: 'https://app.posthog.com',
            loaded: (ph) => {
                if (PUBLIC_ENV === 'development' || url.origin.includes('vercel.app')) {
                    console.log('Posthog excluded from environment');
                    ph.opt_out_capturing();
                }
            },
        });
    }

    if (url.pathname !== '/add-review') {
        reviewProgressBar.set({
            current: 0,
            show: false,
        });
    }

    const supabase = createSupabaseLoadClient({
        supabaseUrl: PUBLIC_SUPABASE_URL,
        supabaseKey: PUBLIC_SUPABASE_KEY,
        event: { fetch },
        serverSession: data?.session,
    });

    const {
        data: { session },
    } = await supabase.auth.getSession();

    if (session) {
        const { data: reviewer, error: reviewerErr } = await supabase
            .from('reviewers')
            .select('name, first_name, last_name, uuid, role_id')
            .eq('user_id', session.user.id)
            .single();
        const isAdmin = !reviewerErr && reviewer?.role_id === 2;

        // analytics attach to user
        if (browser) {
            posthog.identify(session.user.id, {
                email: session.user.email,
                role: roleMap[reviewer?.role_id] || 'unknown',
            });
        }

        return { supabase, session, profile: reviewer, isAdmin, pathname };
    }

    return { supabase, session, profile: {}, isAdmin: false, pathname };
};
