<script>
    import {onMount} from 'svelte';
    import {modalStore} from '@skeletonlabs/skeleton';
    import {IconCaretRight, IconX} from '@tabler/icons-svelte';
    import {fade} from 'svelte/transition';
    import toast from 'svelte-french-toast';
    import UnauthorizedToast from '$components/toasts/UnauthorizedToast.svelte';
    import ReasonSkeleton from '$components/modals/ReasonSkeleton.svelte';

    export let parent;
    let reportReasons = [];
    let reasonCode = -1;
    let reasonLabel = '';
    let reasonDescription = '';
    let reviewUuid = null;
    let reasonText = '';

    onMount(async () => {
        const result = await fetch('/api/reviews/report/reasons');
        const data = await result.json();
        reportReasons = data.reportReasons || [];

        if ($modalStore[0]) {
            reviewUuid = $modalStore[0]?.meta?.reviewUuid;
        }
    });

    function selectReason(reason) {
        reasonCode = reason.id;
        reasonLabel = reason.label;
        reasonDescription = reason.description;
    }

    function resetReason() {
        reasonCode = -1;
        reasonLabel = '';
        reasonDescription = '';
    }

    async function handleSubmit() {
        const res = await fetch('/api/reviews/report', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                reviewUuid,
                reasonCode,
                reasonText,
            }),
        });
        const { success } = await res.json();
        if (success) {
            toast.success('Thank you for reporting this review. We will review it as soon as possible.', {
                position: 'top-center',
                duration: 5000,
            });
            modalStore.close();
            resetReason();
        } else {
            if (res.status === 401) {
                toast(UnauthorizedToast, {
                    position: 'top-center',
                    duration: 5000,
                    icon: '🔒',
                });
                modalStore.close();
            } else {
                toast.error('Something went wrong and we couldn\'t record your feedback. Please try again in a bit.', {
                    position: 'top-center',
                    duration: 5000,
                    icon: '❌',
                });
            }
        }
    }
</script>

{#if $modalStore[0]}
    <div class='w-modal card p-5 shadow-xl space-y-4'>
        <div class="flex flex-row items-center justify-between">
            <h1 class='h1 text-2xl font-bold'>Report Review</h1>
            <button on:click={() => modalStore.close()}><IconX /></button>
        </div>

        {#if reasonCode === -1}
            <p class='text-gray-500'>Please select a reason for reporting this review.</p>
            {#if reportReasons.length > 0}
                {#each reportReasons as reason}
                    <div on:click={() => selectReason(reason)}
                         class='flex flex-row items-center gap-5 justify-between hover:cursor-pointer hover:bg-primary-50 py:3 md:py-3 px-0 md:px-5'>
                        <div>
                            <p class='font-bold'>
                                {reason.label}
                            </p>
                            <p class='text-gray-500 text-sm'>
                                {reason.description}
                            </p>
                        </div>
                        <div>
                            <IconCaretRight size={24} />
                        </div>
                    </div>
                {/each}
            {:else}
                <ReasonSkeleton />
            {/if}
        {:else}
            <div transition:fade>
                <p class='font-bold text-lg'>
                    {reasonLabel}
                </p>
                <p class='text-gray-500 text-sm'>
                    {reasonDescription}
                </p>
                <form on:submit|preventDefault={handleSubmit}>
                    <textarea class='textarea p-5 border-[1px] border-gray-200 mt-2'
                              bind:value={reasonText}
                              placeholder='Tell us more about why you are reporting this review.'
                              spellcheck='true'
                              name='reason_text' id='reason_text' cols='30' rows='4' maxlength='250'></textarea>
                    <p class='text-right text-xs text-gray-500'>({250 - reasonText.length} characters remaining)</p>
                    <div class='w-full flow flow-row text-right mt-5'>
                        <input type='submit' class='btn variant-filled-primary w-full md:w-fit' value='Submit Report'>
                    </div>
                </form>
            </div>
        {/if}
    </div>
{/if}
