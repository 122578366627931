<script>
    import { afterNavigate, invalidate } from '$app/navigation';
    import { onMount } from 'svelte';
    import { AppShell, Drawer, drawerStore, Modal } from '@skeletonlabs/skeleton';
    import Header from '$components/Header.svelte';
    import ReviewProgressBar from '$components/ReviewProgressBar.svelte';
    import { Toaster } from 'svelte-french-toast';
    import ReportReviewModal from '$components/modals/ReportReviewModal.svelte';
    import NavigationLoadingBar from '$lib/vendor/components/NavigationLoadingBar.svelte';
    import ProfileAvatar from '$components/profile/ProfileAvatar.svelte';
    import posthog from 'posthog-js';
    import { browser } from '$app/environment';
    import { page } from '$app/stores';
    import AddBuildingConfirmationModal from '$components/modals/AddBuildingConfirmationModal.svelte';
    import { fade } from 'svelte/transition';


    /** @type {import('../../../.svelte-kit/types/src/routes').LayoutServerData} */
    export let data;

    let { supabase, session, profile, isAdmin } = data;
    $: ({ supabase, session, profile, isAdmin } = data);

    onMount(() => {
        const { data } = supabase.auth.onAuthStateChange((event, _session) => {
            if (_session?.expires_at !== session?.expires_at) {
                invalidate('supabase:auth');
            }
        });

        return () => data.subscription.unsubscribe();
    });

    afterNavigate(() => {
        document.getElementById('page')?.scrollTo(0, 0);
    });

    const modalComponentRegistry = {
        reportReview: {
            ref: ReportReviewModal,
            props: {},
            slot: '<p>Loading...</p>',
        },
        addBuildingConfirmation: {
            ref: AddBuildingConfirmationModal,
            props: {},
            slot: '<p>Loading...</p>',
        }
    };

    function closeDrawer() {
        drawerStore.close();
    }

    $: $page.url.pathname, browser && posthog.capture('$pageview');
</script>

<NavigationLoadingBar class='text-primary-500' />
<Toaster />
<Modal components={modalComponentRegistry} />
<Drawer>
    <div class='flex flex-col px-5 py-5 text-left'>
        {#if JSON.stringify(profile) !== "{}"}
            <ProfileAvatar on:clickProfile={closeDrawer} name='{profile?.first_name} {profile?.last_name}'
                           showName={true} />
        {:else}
            <a on:click={closeDrawer} href='/login' class='btn variant-filled-primary rounded-xl w-full'>
                Login
            </a>
        {/if}
        <a on:click={closeDrawer}
           class={`btn btn-sm text-black`} href='/buildings/search'>Search by Area</a>
        <a on:click={closeDrawer}
           class={`btn btn-sm text-black`} href='/buildings/toronto'>View All Buildings</a>
        {#if isAdmin}
            <a href='/admin' class='btn variant-filled-error btn-sm'>Go to Admin Panel</a>
        {/if}
    </div>
</Drawer>

{#if isAdmin}
    <div class='hidden sm:block absolute bottom-0 right-0 z-40'>
        <a href='/admin' class='btn variant-filled-error btn-sm'>Go to Admin Panel</a>
    </div>
{/if}

<AppShell>
    <svelte:fragment slot='header'>
        <div class='container mx-auto sm:w-full max-w-5xl h-full'>
            <Header {profile} />
        </div>
    </svelte:fragment>
    <!-- (sidebarLeft) -->
    <!-- (sidebarRight) -->
    <!-- Router Slot -->

    {#if $page.url.pathname !== '/add-review' || $page.url.searchParams.get('b_id') === null}
        <div class='container mx-auto sm:w-full max-w-5xl px-4 h-full mt-3 mb-10'>
            {#key data.pathname}
            <div class='flex flex-col max-h-full' in:fade={{ duration: 200, delay: 300 }}
                 out:fade={{ duration: 200 }}>
                <slot />
            </div>
            {/key}
        </div>
    {:else}
        {#key data.pathname}
            <div in:fade={{ duration: 200, delay: 300 }}
                 out:fade={{ duration: 200 }}
                class='overflow-hidden'>
                <slot />
            </div>
        {/key}
    {/if}

    <!-- ---- / ---- -->
    <svelte:fragment slot='pageFooter'>
        {#if $page.url.pathname !== '/add-review'}
            <div class='w-full bg-surface-300 flex flex-col justify-center text-xs text-neutral-500'>
                <div class='flex flex-row justify-center content-center py-5 gap-5'>
                    <a href='/about' target='_blank' class='text-primary-700 hover:underline underline-offset-4'>About
                        Us</a>
                    <a href='/about/terms-of-service' target='_blank'
                       class='text-primary-700 hover:underline underline-offset-4'>Terms of Service</a>
                    <a href='/about/privacy-policy' target='_blank'
                       class='text-primary-700 hover:underline underline-offset-4'>Privacy Policy</a>
                </div>
                <div class='flex flex-row justify-center content-center mb-3'>
                    <p class='py-1'>
                        &copy; {new Date().getFullYear()} CondoGenius.io | All rights reserved.
                    </p>
                </div>
            </div>
        {/if}
    </svelte:fragment>

    <svelte:fragment slot='footer'>
        <ReviewProgressBar />
    </svelte:fragment>
</AppShell>
