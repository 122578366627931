<script>
    import reviewProgressBar from '$lib/stores/reviewProgressBar.js';
    import { ProgressBar } from '@skeletonlabs/skeleton';

    let animation;

    $: if ($reviewProgressBar.current < 100) {
        animation = 'animate-pulse'
    } else {
        animation = 'animate-none'
    }
</script>

{#if $reviewProgressBar.show}
    <div class='transition-all ease-in-out duration-500'>
        <ProgressBar
            label="Progress Bar"
            value={$reviewProgressBar.current}
            max={100}
            rounded="rounded-none"
            height="h-3"
            track='bg-primary-200'
            meter='cta rounded-tr-full rounded-br-full transition-all ease-in-out {animation}'
        />
    </div>
{/if}
